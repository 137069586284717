<template>
  <div class="mt-2 container">
    <div class="col-12">
      <form @submit.prevent="procesarFormulario">
        <div class="row">
          <div class="col-lg-4">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-map-marker"></i> Repartición: {{ usuario.reparticion }}
              </div>
              <div class="card-body">        
                  <div class="form-group">
                    <label class="col-form-label col-form-label-sm" >NIT/CI:</label>
                    <small
                      class="text-danger text-helper"
                      v-if="!$v.empresa.nit.required"
                      > * Campo requerido
                    </small>

                    <input class="form-control form-control-sm" type="text" placeholder="Ingrese NIT/CI..." v-model.trim="$v.empresa.nit.$model" @blur="completarCliente()">
                  </div>
                  <div class="form-group">
                    <label class="col-form-label col-form-label-sm" >Nombre/Razón Social:</label>
                    <small
                      class="text-danger text-helper"
                      v-if="!$v.empresa.razonsocial.required"
                      > * Campo requerido
                    </small>
                    <span
                      class="text-danger text-helper"
                      v-if="!$v.empresa.razonsocial.letras"
                      > Ingreso indebido de carácter especial
                    </span>

                    <input class="form-control form-control-sm" type="text" placeholder="Ingrese Nombre/Razón Social..." v-model.trim="$v.empresa.razonsocial.$model" @keyup="empresa.razonsocial = empresa.razonsocial.toUpperCase()" >
                  </div>
                  <div class="form-group">
                    <label class="col-form-label col-form-label-sm" >Correo:</label>
                    <small
                      class="text-danger text-helper"
                      v-if="!$v.empresa.correo.required"
                      > * Campo requerido
                    </small>
                    <small 
                      class="text-danger text-helper" 
                      v-if="!$v.empresa.correo.email">
                      Correo electrónico es incorrecto
                    </small>

                    <input class="form-control form-control-sm" type="text" placeholder="Ingrese Correo..." v-model.trim="$v.empresa.correo.$model">
                  </div>
                  <div class="form-group">
                    <label class="col-form-label col-form-label-sm" >Teléfono/Celular:</label>
                    <small
                      class="text-danger text-helper"
                      v-if="!$v.empresa.telefono.required"
                      > * Campo requerido
                    </small>
                    <small  
                      class="text-danger text-helper" 
                      v-if="!$v.empresa.telefono.numeros">
                      Solo puede ingresar números
                    </small>
                    <small   
                      class="text-danger text-helper" 
                      v-if="!$v.empresa.telefono.minLength">
                      Ingrese al menos 8 números
                    </small>
                    <small  minLength 
                      class="text-danger text-helper" 
                      v-if="!$v.empresa.telefono.maxLength">
                      Ingrese máximo 8 números
                    </small>
                    <input class="form-control form-control-sm" type="text" placeholder="Ingrese Celular..." v-model.trim="$v.empresa.telefono.$model">
                  </div>
              </div>
            </div> 
          </div>
          <div class="col-lg-8">
            <div class="card">
              <div class="card-header">
                <i class="fa fa-tag"></i> Orden de Pago por venta de Servicios
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="form-group">
                      <label class="col-form-label col-form-label-sm" >Sub Repartición:</label>
                      <select
                          class="form-select form-select-sm"                        
                          v-model.trim="$v.orden.id_categoria.$model"
                          @change="onChangeCategoria($event)"
                        >
                          <option v-for="(item,index) in categorias" :key="index" :value="item.id_categoria">{{item.descripcion}}</option>
                      </select>
                    </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <div class="form-group">
                      <label class="col-form-label col-form-label-sm" >Rubros:</label>
                      <select
                          class="form-select form-select-sm"                        
                          v-model.trim="$v.orden.id_rubro.$model"
                          @change="onChangeRubro($event)"
                        >
                          <option v-for="(item,index) in rubros" :key="index" :value="item.id_rubro">{{item.descripcion}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="form-group">
                      <label class="col-form-label col-form-label-sm" >Aranceles:</label>
                      <select
                          class="form-select form-select-sm"                        
                          v-model.trim="$v.orden.id_arancel.$model"
                          @change="onChangeArancel($event)"
                        >
                          <option v-for="(item,index) in aranceles" :key="index" :value="item.id_arancel">{{item.descripcion}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-3">

                    <div class="form-group">
                      <label class="col-form-label col-form-label-sm">Cantidad:</label>
                      <div class="form-group">                        
                        <div class="input-group">
                          <a class="btn btn-danger btn-sm" @click="disminuyeCantidad()"><i class="fa fa-minus"></i></a>
                          <input type="text" class="form-control form-control-sm" placeholder="1" v-model.trim="$v.orden.cantidad.$model" @keyup="calculoTotal()" readonly>
                          <a class="btn btn-success btn-sm" @click="aumentaCantidad()"><i class="fa fa-plus"></i></a>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-4">     
                    
                    <div class="alert alert-warning text-center">                     
                      <h5 class="alert-heading"> {{ orden.costo }} Bs.-</h5>
                      <hr>
                      <h6 class="alert-heading">Precio Unitario</h6>                     
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="alert alert-success text-center">                     
                      <h5 class="alert-heading"> {{ orden.importe }} Bs.-</h5>
                      <hr>
                      <h6 class="alert-heading">TOTAL</h6>                     
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group">
                    <label class="col-form-label col-form-label-sm" >Especificaciones:</label>
                    <input class="form-control form-control-sm" type="text" placeholder="Especificaciones..." v-model.trim="$v.orden.especificacion.$model" @keyup="orden.especificacion = orden.especificacion.toUpperCase()">
                  </div>                  
                </div>
                <hr>
                <div class="row">
                  <div class="col-4">
                    <button class="btn btn-primary" type="submit" :disabled="$v.$invalid"><i class="fa fa-save"></i> Registrar</button>
                  </div>                  
                </div>

              </div>
            </div> 
          </div>
        </div>
      </form>
    </div>
    
  </div>
</template>
<script>
import { required, email, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import Loading from '@/components/Loading';
import moment from 'moment';

const letras = helpers.regex("alpha", /^[a-zA-ZñÑüÜäÄöÖ ]*$/);
const numeros = helpers.regex("numb",/^[0-9]*$/);

export default { 
  data() {
    return {
      cargando:false,
      aranceles: [],
      rubros: [],
      categorias: [],
      arancel: {},
      submitted: false,
      usuario: {},
      empresa: {
        nit:'',
        razonsocial: '',
        telefono: '',
        correo:''
      },
      orden: {
        cantidad: 1,
        id_rubro: 0,
        id_arancel: 0,
        id_categoria:0,
        costo: 0,
        cantidad: 1,
        importe: 0,
        especificacion:'', 
        razonsocial:'',
        nitci:'',
        idempresa:0
      },

    }
  },
  components: {
    Loading    
  },
  
  methods: {
    async listarAranceles(id) {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/listarAranceles/${id}`);
        this.aranceles = resultados.data.contenido.registros; 
        this.orden.id_arancel = this.aranceles[0].id_arancel;
        this.orden.costo = this.aranceles[0].costo;
        this.calculoTotal();
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    async listarDatos () {      
      this.cargando= true;
      try {
        let res = await this.axios.get(`/usuarioData`);
        this.usuario = res.data.contenido[0];  
        this.cargando = false;
      } catch (error) {
        console.log(error);
      }      
    },
    async listarRubros(id) {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/listarRubros/${id}`);
        this.rubros = resultados.data.contenido.registros; 
        this.orden.id_rubro = this.rubros[0].id_rubro; 
        this.listarAranceles(this.orden.id_rubro);
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    async listarCategorias() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/listarCategorias`);
        this.categorias = resultados.data.contenido.registros; 
        this.orden.id_categoria = this.categorias[0].id_categoria; 
        this.listarRubros(this.orden.id_categoria);
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    async onChangeCategoria(event) {
      this.listarRubros(this.orden.id_categoria);
    },
    async onChangeRubro(event) {
      this.listarAranceles(this.orden.id_rubro);
    },
    async onChangeArancel(event){
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/arancel/${this.orden.id_arancel}`);
        this.arancel = resultados.data.contenido.registros;         
        this.orden.costo = this.arancel[0].costo; 
        this.calculoTotal();
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    aumentaCantidad(){
      this.orden.cantidad++;
      this.calculoTotal();
    },
    disminuyeCantidad(){
      if(this.orden.cantidad>1){        
        this.orden.cantidad--;
        this.calculoTotal();
      }
    },
    calculoTotal(){
      this.orden.importe = Number(this.orden.cantidad) * Number(this.orden.costo);
    },
    async completarCliente(event){
      if(this.empresa.nit=='' || this.empresa.nit==undefined){
        return;
      }
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/empresa/${this.empresa.nit}`);
        let res = resultados.data.contenido.registros;    
        if(res.length>0){
          this.empresa = res[0];
          this.orden.idempresa = this.empresa.idempresa;
          this.orden.razonsocial = this.empresa.razonsocial;
          this.orden.nitci = this.empresa.nit;
          this.orden.correo = this.empresa.correo;
          this.orden.telefono = this.empresa.telefono;
        }else{
          this.orden.idempresa = 0;
          this.orden.razonsocial = '';
          this.orden.nitci = '';
          this.orden.correo = '';
          this.orden.telefono = '';
        }    
                
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
    async procesarFormulario(){
      this.cargando = true;
      this.submitted = true;

      this.$v.$touch();
      if(this.$v.$invalid){
        this.cargando = false;
        return;
      }

      this.orden.razonsocial = this.empresa.razonsocial;
      this.orden.nitci = this.empresa.nit;
      this.orden.correo = this.empresa.correo;
      this.orden.telefono = this.empresa.telefono;
      
      try {
        await this.axios.post(`/orden`, this.orden);      
        this.cargando = false;
        this.$alert("Se registró correctamente","Atención","success"); 
        this.$router.push('ordenes');
      } catch (error) {

        console.log(error);
        this.cargando = false;
      }    

    }
  },
  created(){
    this.orden.cantidad= 1;
    this.listarCategorias();
    this.listarDatos();
  },
  validations: {
    empresa: {
      nit: { required, minLength: minLength(5), maxLength: maxLength(10) },
      razonsocial: { required, letras },      
      telefono: { required, minLength: minLength(8), maxLength: maxLength(8), numeros},
      correo: { required, email}
    },
    orden: {
      id_rubro: {required},
      id_arancel: {required},
      id_categoria:{required},
      cantidad: {required},
      especificacion: {letras},
    }
  }
}
</script>
